<template>
  <div>
    <sm-loader v-if="getLoanData.loading" />
    <div class="flex flex-col text-14 mb-5 mt-2 font-bold" v-else>
      <span>Total Requested Amount: {{ totalRequestedAmount | currency }}</span>
      <span>Total Amount To Be Disbursed: {{ totalLoanAmount | currency }}</span>
    </div>

    <div class="border border-solid border-blue-200">
      <datatable
        :data="loans.data"
        :columns="loans.columns"
        :query="searchQuery"
        :loading="loans.loading"
        :on-click="click"
        v-model="selection"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/admin/personal/loans?status=approved`"
        :search-field="searchField"
        ref="table"
      />
    </div>

    <modals-user
      ref="viewModal"
      :selected-user="selectedUser"
      :allow-delete="false"
    />

  </div>
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      default: ''
    },
    searchField: {
      type: [HTMLInputElement],
      default: null
    }
  },
  data() {
    return {
      selection: [],
      checkmark: require('@/assets/checkmark-base.svg'),
      lenders: this.$options.resource([]),
      loans: this.$options.resource([], {
        selected: null,
        columns: [
          {
            name: 'id',
            th: 'Loan ID'
          },
          {
            name: 'name',
            th: 'User Name',
            render: (loan) => `${loan.user?.name} ${loan.user?.last_name || ''}`
          },
          {
            name: 'company',
            th: 'Company',
            render: (loan) => loan.user.company?.name
          },
          {
            name: 'requested_amount',
            th: 'Amount Requested',
            render: (loan) => `₦ ${this.$options.filters.currency(loan?.requested_amount)}`,
          },
          {
            name: 'loan_amount',
            th: 'Amount to disburse',
            render: (loan) => `₦ ${this.$options.filters.currency(loan.loan_amount)}`
          },
          {
            name: "tenure",
            th: "Tenure",
            render: (loan) => `${loan?.tenure} Months`
          },
          {
            name: 'interest_rate',
            th: 'Interest Rate'
          },
          {
            name: 'email',
            th: 'Bank Account',
            render: (loan) => loan.user.profile?.account_no
          },
          {
            name: 'phone',
            th: 'Bank Name',
            render: (loan) => this.$options.filters.bankName(loan.user.profile?.bank_name, this.banks)
          },
          {
            name: 'status',
            th: 'Status',
            render: () => {
              return `<div class="badge badge-blue-soft-outline">
                Pending User Action
              </div>`;
            },
          },
          {
            name: 'offer-letter',
            th: 'Offer Letter',
            render: (loan) => {
              if (loan.status.match(/approved|accepted|disbursed/) 
                  && loan?.source !== this.credpalPay)
              {
                return `
                  <a
                    href="/${this.ENV_VUE_APP_ADMIN_PATH}/offer-letter/${loan.id}"
                    target="_blank"
                    class="btn btn-sm btn-gray-soft"
                  >
                    View Offer Letter
                  </a>
                `
              }
            },
          },
        ],
        successes: [],
        failures: []
      }),
      form: this.$options.basicForm(['lender']),
      getLoanData: this.$options.resource({})
    }
  },
  computed: {
    banks() {
      return this.$store.state.banks;
    },
    lenderOptions() {
      return this.lenders.data?.map(lender => ({ title: lender.name, value: lender.id }));
    },
    selectedUser() {
      return this.loans.selected?.user;
    },
    totalRequestedAmount() {
      return this.getLoanData?.data?.totalRequested
    },
    totalLoanAmount() {
      return this.getLoanData?.data?.totalLoanAmount
    },
  },
  beforeMount() {
    // this.getLoans();
    this.getLenders();
    this.getTotalRequestedAndLoanAmount()
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    click(loan) {
      this.loans.selected = loan;
      this.$refs.viewModal.open();
    },
    clear() {
      this.$refs.table.clearSelection();
    },
    async getLoans() {
      this.$refs.table.loadAjaxData();
    },
    async getLenders() {
      this.lenders.loading = true;
      await this.sendRequest('admin.lenders.all', {
        success: response => {
          this.lenders.data = response.data.lenders;
        }
      });
      this.lenders.loading = false;
    },
    async getTotalRequestedAndLoanAmount() {
      this.getLoanData.loading = true
      await this.sendRequest('admin.loans.personal.total', 'approved', {
        success: response => this.getLoanData.data = response.data.data,
        error: error => console.log(error)
      });
      this.getLoanData.loading = false
    },
  }
}
</script>
